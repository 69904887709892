import React, { useContext, useEffect, useState } from 'react';
import { UsersContext } from '../context/UsersContext';
import swal from 'sweetalert';
import API_URL from '../apiConfig';

const DataPengguna = () => {
  const { fetchData, deleteUser, updateUser, createUser } = useContext(UsersContext);
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({ nip: '', name: '', email: '', password: '', role: '' });
  const [isAdding, setIsAdding] = useState(false);

  const fetchUsers = async () => {
    const data = await fetchData(`${API_URL}/api/viewuser`);
    setUsers(data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      swal("Berhasil!", "User berhasil dihapus.", "success");
      fetchUsers(); // Refresh the user list after deletion
    } catch (error) {
      swal("Gagal!", "User gagal dihapus.", "error");
    }
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setFormData({ nip: user.nip || '', name: user.name || '', email: user.email || '', password: '', role: user.role || '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validasi bahwa semua field diisi
    if (!formData.nip || !formData.name || !formData.email || !formData.password || !formData.role) {
      swal("Gagal!", "Semua field harus diisi.", "error");
      return;
    }

    if (isAdding) {
      await createUser(formData);
      swal("Berhasil!", "User berhasil ditambahkan.", "success");
      setIsAdding(false);
    } else {
      await updateUser(editingUser.id, formData);
      swal("Berhasil!", "User berhasil diupdate.", "success");
      setEditingUser(null);
    }
    fetchUsers(); // Refresh the user list after update or add
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Data Pengguna</h1>

      <button
        onClick={() => { setIsAdding(true); setFormData({ nip: '', name: '', email: '', password: '', role: '' }); }}
        className="bg-green-500 text-white py-1 px-2 rounded mb-4"
      >
        Tambah User
      </button>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b text-left">NIP</th>
            <th className="py-2 px-4 border-b text-left">Name</th>
            <th className="py-2 px-4 border-b text-left">Email</th>
            <th className="py-2 px-4 border-b text-left">Role</th>
            <th className="py-2 px-4 border-b text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(users) && users.length > 0 ? (
            users.map((user) => (
              <tr key={user.id}>
                <td className="py-2 px-4 border-b">{user.nip}</td>
                <td className="py-2 px-4 border-b">{user.name}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">{user.role}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleEdit(user)}
                    className="bg-blue-500 text-white py-1 px-2 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(user.id)}
                    className="bg-red-500 text-white py-1 px-2 rounded"
                  >
                    Hapus
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="py-2 px-4 border-b text-center">
                No users found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {(editingUser || isAdding) && (
        <div className="mt-4">
          <h2 className="text-xl font-bold mb-2">{isAdding ? 'Tambah User' : 'Edit User'}</h2>
          <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow-md">
            <div className="mb-4">
              <label className="block text-gray-700">NIP</label>
              <input
                type="text"
                name="nip"
                value={formData.nip}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Role</label>
              <input
                type="text"
                name="role"
                value={formData.role}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            
            <div className="flex justify-end">
              <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded">
                Save
              </button>
              <button
                type="button"
                onClick={() => { setEditingUser(null); setIsAdding(false); }}
                className="ml-2 bg-gray-500 text-white py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default DataPengguna;
