import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext';
import API_URL from '../apiConfig';

export const PeminjamanUserContext = createContext();

export const PeminjamanUserProvider = ({ children }) => {
const {userData} = useContext(UserContext);
 
  const [kembali, setKembali] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [back,setBack] =useState([]);

//   useEffect(() => {
//     fetchPeminjamanuser();
//   }, []);

  const fetchPeminjamanuser = async () => {
    setLoading(true);
    setError(null);
    try {
        const response = await axios.get(`${API_URL}/api/peminjaman/viewuserpinjam/${userData.nip}`);
      setKembali(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPengembalianuser = async () => {
    setLoading(true);
    setError(null);
    try {
        const response = await axios.get(`${API_URL}/api/peminjaman/viewuserpengembalian/${userData.nip}`);
        setBack(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PeminjamanUserContext.Provider value={{ back,kembali, loading, error, fetchPeminjamanuser,fetchPengembalianuser }}>
      {children}
    </PeminjamanUserContext.Provider>
  );
};
