import React, { useContext, useEffect, useState } from 'react';
import { PeminjamanUserContext } from '../context/PeminjamanUserContext';

const PengembalianUser = () => {
  const {  back, loading, error,fetchPengembalianuser
     } = useContext(PeminjamanUserContext);

  useEffect(() => {
    fetchPengembalianuser();
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Daftar Peminjaman</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              No Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              NIP
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nama
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pengembalian
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Denda
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ISBN
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            tgl Dikembalikan
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {back.map((back) => (
            <tr key={back.no_pinjaman}>
              <td className="px-6 py-4 whitespace-nowrap">{back.no_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.nip}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.nama}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.tgl_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.tgl_pengembalian}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.status}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.denda}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.isbn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{back.tgl_dikembalikan}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PengembalianUser;