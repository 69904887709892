import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../apiConfig';

const DendaContext = React.createContext();

const DendaProvider =({children})=>{
    const [denda,setDenda] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchDenda();
      }, []);

      
  const fetchDenda = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/denda/viewdenda`);
      setDenda(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const editDenda = async (id, denda) => {
    try {
      const response = await axios.put(`${API_URL}/api/denda/editdenda/${id}`, denda);
      setDenda(prevdenda => prevdenda.map((b) => (b.id === id ? response.data : b)));
    } catch (error) {
      setError(error.message);
    }
  };

  return(
    <DendaContext.Provider
    value={{ 
        denda,
        loading,
        error,
        fetchDenda,
        editDenda
     }}
    >
        {children}
    </DendaContext.Provider>
  );
};

export { DendaProvider, DendaContext };