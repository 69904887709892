import React, { useContext, useEffect, useState } from 'react';
import { PeminjamanContext } from '../context/PeminjamanContext';

const Pengembalian = () => {
  const {  kembali,
    loading,
    error,
    fetchPengembalian,
     } = useContext(PeminjamanContext);

  useEffect(() => {
    fetchPengembalian();
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Daftar Peminjaman</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              No Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              NIP
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nama
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pengembalian
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Denda
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ISBN
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            tgl Dikembalikan
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {kembali.map((kembalian) => (
            <tr key={kembalian.no_pinjaman}>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.no_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.nip}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.nama}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.tgl_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.tgl_pengembalian}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.status}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.denda}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.isbn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembalian.tgl_dikembalikan}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Pengembalian;