import React, { useContext, useEffect, useState } from 'react';
import { PeminjamanUserContext } from '../context/PeminjamanUserContext';

const PeminjamanUser = () => {
  const { kembali, loading, error, fetchPeminjamanuser} = useContext(PeminjamanUserContext);

  useEffect(() => {
    fetchPeminjamanuser();
  }, []);


  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Daftar Peminjaman</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              No Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              NIP
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nama
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pengembalian
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Denda
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ISBN
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {kembali.map((kembali) => (
            <tr key={kembali.no_pinjaman}>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.no_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.nip}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.nama}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.tgl_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.tgl_pengembalian}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.status}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.denda}</td>
              <td className="px-6 py-4 whitespace-nowrap">{kembali.isbn}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PeminjamanUser;