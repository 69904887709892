import React, { createContext, useState } from 'react';

// Buat context baru
const AuthContext = createContext({
});

// Buat provider untuk context ini
const AuthProvider = ({ children }) => {
const [state, setState] = useState({
user: null,
isAuthenticated: false,
});


const fetchData = async (url, method = 'GET', body = null) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);
    return response.json();
};

return (
    <AuthContext.Provider value={{ state, setState, fetchData }}>
        {children}
    </AuthContext.Provider>
);
};

export { AuthContext, AuthProvider  };