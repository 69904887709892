import React, { createContext, useState,useCallback } from 'react';
import API_URL from '../apiConfig';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState([]); 

  const fetchData = async (url, method = 'GET', options = {}) => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    };

    options.headers = {
      ...defaultHeaders,
      ...options.headers,
    };

    try {
      const response = await fetch(url, { method, ...options });
      return response;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  const fetchUsers = useCallback(async () => {
    const data = await fetchData(`${API_URL}/api/viewuser`);
    setUsers(data);
  }, []);

  return (
    <UserContext.Provider value={{ userData,fetchUsers,users, setUserData, fetchData }}>
      {children}
    </UserContext.Provider>
  );
};