import React, { useContext, useEffect, useState } from 'react';
import { PeminjamanContext } from '../context/PeminjamanContext';

const Peminjaman = () => {
  const { peminjam, loading, error, fetchPeminjaman, addPeminjaman,pengembalian } = useContext(PeminjamanContext);
  const [formData, setFormData] = useState({
    nip: '',
    nama: '',
    tgl_pinjaman: '',
    tgl_pengembalian: '',
    status: '',
    denda: '',
    isbn: ''
  });

  useEffect(() => {
    fetchPeminjaman();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handlePengembalian = async (nip, isbn,no_pinjaman) => {
    try {
      // Panggil fungsi pengembalian dengan NIP dan ISBN sebagai argumen
      await pengembalian(nip, isbn,no_pinjaman);
  
      // Perbarui daftar peminjaman setelah pengembalian berhasil
      fetchPeminjaman(); 
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addPeminjaman(formData);
    setFormData({
      nip: '',
      nama: '',
      tgl_pinjaman: '',
      tgl_pengembalian: '',
      status: '',
      denda: '',
      isbn: ''
    });
    fetchPeminjaman(); // Refresh list after adding new entry
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Daftar Peminjaman</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              No Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              NIP
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nama
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pinjaman
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tgl Pengembalian
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Denda
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ISBN
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {peminjam.map((peminjaman) => (
            <tr key={peminjaman.no_pinjaman}>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.no_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.nip}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.nama}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.tgl_pinjaman}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.tgl_pengembalian}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.status}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.denda}</td>
              <td className="px-6 py-4 whitespace-nowrap">{peminjaman.isbn}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {peminjaman.status === 'terpinjam' && (
                  <button onClick={() => handlePengembalian(peminjaman.nip, peminjaman.isbn,peminjaman.no_pinjaman)} className="bg-blue-500 text-white px-4 py-2 rounded-md">Pengembalian</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Tambah Peminjaman</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="nip" value={formData.nip} onChange={handleChange} placeholder="NIP" required className="border-gray-300 border rounded-md p-2 mb-2" />
        <input type="text" name="nama" value={formData.nama} onChange={handleChange} placeholder="Nama" required className="border-gray-300 border rounded-md p-2 mb-2" />
        <input type="date" name="tgl_pinjaman" value={formData.tgl_pinjaman} onChange={handleChange} required className="border-gray-300 border rounded-md p-2 mb-2" />
        <input type="date" name="tgl_pengembalian" value={formData.tgl_pengembalian} onChange={handleChange} required className="border-gray-300 border rounded-md p-2 mb-2" />
        <input type="text" name="status" value="terpinjam" readOnly className="border-gray-300 border rounded-md p-2 mb-2"/>
        <input type="number" name="denda" value={formData.denda} onChange={handleChange} placeholder="Denda" step="0.01" required className="border-gray-300 border rounded-md p-2 mb-2" />
        <input type="text" name="isbn" value={formData.isbn} onChange={handleChange} placeholder="ISBN" required className="border-gray-300 border rounded-md p-2 mb-2" />
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">Tambah Peminjaman</button>
      </form>
    </div>
  );
};

export default Peminjaman;