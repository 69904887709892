import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import DataPengguna from './DataPengguna';
import Buku from './Buku';
import Pengembalian from './Pengembalian';
import Denda from './Denda';
import Home from './Home';
import Peminjaman from './Peminjaman';
import PeminjamanUser from './PeminjamanUser';
import PengembalianUser from './PengembalianUser.js';
import API_URL from '../apiConfig.js';

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData, setUserData, fetchData } = useContext(UserContext);
  const [currentMenu, setCurrentMenu] = useState('home'); // Menu default yang ditampilkan pertama kali

  const handleMenuClick = (menu) => {
    setCurrentMenu(menu);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetchData(`${API_URL}/api/user`, 'GET', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_URL}/api/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        // Hapus token dari local storage
        localStorage.removeItem('token');
        setUserData(null);
        navigate('/');
      } else {
        throw new Error('Failed to logout');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Konten untuk setiap menu
  const renderContent = () => {
    switch (currentMenu) {
      case 'home':
        return <Home />;
      case 'data-pengguna':
        return <DataPengguna />;
      case 'buku':
        return <Buku />;
      case 'pengembalian':
        return <Pengembalian />;
      case 'peminjaman':
        return <Peminjaman/>
      case 'denda':
        return <Denda />;
      case 'peminjamanUser':
      return <PeminjamanUser/>;
      case 'pengembalianUser':
        return <PengembalianUser/>
      default:
        return <Home />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="bg-gray-800 text-white w-64 flex flex-col">
        <div className="p-4 border-b border-gray-700">PerpustakaanKU</div>
        <div className="p-4 border-b border-gray-700">
    {userData && userData.role === 'admin' && (
      <>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('home')}>Home</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('data-pengguna')}>Data Pengguna</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('buku')}>Buku</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('peminjaman')}>Peminjaman</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('pengembalian')}>Pengembalian</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('denda')}>Denda</div>
      </>
    )}
    {userData && userData.role === 'user' && (
      <>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('home')}>Home</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('peminjamanUser')}>Peminjaman</div>
        <div className="block p-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleMenuClick('pengembalianUser')}>Pengembalian</div>

      </>
    )}
  </div>
        <div className="p-4 border-t border-gray-700 cursor-pointer" onClick={handleLogout}>
          Logout
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8">
        {/* Navbar */}
        <div className="bg-white shadow-lg p-4 mb-8 flex justify-between items-center">
          <div>Navbar</div>
          <div className="flex items-center">
            <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
            <div className="ml-2 cursor-pointer" onClick={handleLogout}>Logout</div>
          </div>
        </div>
        
        {/* Content */}
        <div className="bg-white shadow-md p-4">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
