import React, { useContext, useEffect, useState } from 'react';
import { BukuContext } from '../context/BukuContext';

const Buku = () => {
  const { bukus, fetchBukus, loading, error, editBuku, deleteBuku, addBuku } = useContext(BukuContext);
  const [editId, setEditId] = useState(null);
  const [editedBuku, setEditedBuku] = useState({});
  const [newBuku, setNewBuku] = useState({
    judul: "",
    ISBN: "",
    penerbit: "",
    tahun: "",
    stok: ""
  });

  useEffect(() => {
    fetchBukus();
  }, []);

  const handleEdit = (id) => {
    setEditId(id);
    const selectedBuku = bukus.find(buku => buku.id === id);
    setEditedBuku(selectedBuku);
  };

  const handleSaveEdit = async () => {
    await editBuku(editId, editedBuku);
    setEditId(null);
    setEditedBuku({});
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this book?')) {
      await deleteBuku(id);
    }
  };

  const handleAddBuku = async () => {
    await addBuku(newBuku);
    setNewBuku({
      judul: "",
      ISBN: "",
      penerbit: "",
      tahun: "",
      stok: ""
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Daftar Buku</h1>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">Error: {error}</p>}
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left">Judul</th>
            <th className="px-4 py-2 text-left">ISBN</th>
            <th className="px-4 py-2 text-left">Penerbit</th>
            <th className="px-4 py-2 text-left">Tahun</th>
            <th className="px-4 py-2 text-left">Stok</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {bukus.map(buku => (
            <tr key={buku.id} className="border-b">
              <td className="px-4 py-2">{editId === buku.id ? <input type="text" value={editedBuku.judul} onChange={(e) => setEditedBuku({ ...editedBuku, judul: e.target.value })} /> : buku.judul}</td>
              <td className="px-4 py-2">{editId === buku.id ? <input type="text" value={editedBuku.ISBN} onChange={(e) => setEditedBuku({ ...editedBuku, ISBN: e.target.value })} /> : buku.ISBN}</td>
              <td className="px-4 py-2">{editId === buku.id ? <input type="text" value={editedBuku.penerbit} onChange={(e) => setEditedBuku({ ...editedBuku, penerbit: e.target.value })} /> : buku.penerbit}</td>
              <td className="px-4 py-2">{editId === buku.id ? <input type="number" value={editedBuku.tahun} onChange={(e) => setEditedBuku({ ...editedBuku, tahun: parseInt(e.target.value) })} /> : buku.tahun}</td>
              <td className="px-4 py-2">{editId === buku.id ? <input type="number" value={editedBuku.stok} onChange={(e) => setEditedBuku({ ...editedBuku, stok: parseInt(e.target.value) })} /> : buku.stok}</td>
              <td className="px-4 py-2">
                {editId === buku.id ? (
                  <>
                    <button onClick={handleSaveEdit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2">Save</button>
                    <button onClick={() => setEditId(null)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4">Cancel</button>
                  </>
                ) : (
                  <>
                    <button onClick={() => handleEdit(buku.id)} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mr-2">Edit</button>
                    <button onClick={() => handleDelete(buku.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4">Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <h2 className="text-xl font-bold mb-2">Tambah Buku Baru</h2>
        <input
          type="text"
          placeholder="Judul"
          className="px-4 py-2 mr-2"
          value={newBuku.judul}
          onChange={(e) => setNewBuku({ ...newBuku, judul: e.target.value })}
        />
        <input
          type="text"
          placeholder="ISBN"
          className="px-4 py-2 mr-2"
          value={newBuku.ISBN}
          onChange={(e) => setNewBuku({ ...newBuku, ISBN: e.target.value })}
        />
        <input
          type="text"
          placeholder="Penerbit"
          className="px-4 py-2 mr-2"
          value={newBuku.penerbit}
          onChange={(e) => setNewBuku({ ...newBuku, penerbit: e.target.value })}
        />
        <input
          type="number"
          placeholder="Tahun"
          className="px-4 py-2 mr-2"
          value={newBuku.tahun}
          onChange={(e) => setNewBuku({ ...newBuku, tahun: parseInt(e.target.value) })}
        />
        <input
          type="number"
          placeholder="Stok"
          className="px-4 py-2 mr-2"
          value={newBuku.stok}
          onChange={(e) => setNewBuku({ ...newBuku, stok: parseInt(e.target.value) })}
        />
        <button onClick={handleAddBuku} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4">Add</button>
      </div>
    </div>
  );
};

export default Buku;
