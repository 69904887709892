import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../apiConfig';

const PeminjamanContext = React.createContext();

const PeminjamanProvider =({children})=>{
    const [peminjam,setPeminjam] = useState([]);
    const [kembali,setKembali] =useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetchPeminjaman();
      fetchPengembalian();
      }, []);

      
  const fetchPeminjaman = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/peminjaman/viewpeminjaman`);
      setPeminjam(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const fetchPengembalian = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/peminjaman/viewpengembalian`);
      setKembali(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const addPeminjaman = async (pinjam) => {
    try {
      const response = await axios.post(`${API_URL}/api/peminjaman/addpeminjaman`, pinjam);
      setPeminjam([...peminjam, response.data]);
    } catch (error) {
      setError(error.message);
    }
  };
  const pengembalian = async (nip, isbn,no_pinjaman) => {
    try {
      const response = await axios.post(`${API_URL}/api/peminjaman/pengembalian`, { nip, isbn,no_pinjaman });
      fetchPeminjaman(); // Refresh list after successful return
    } catch (error) {
      setError(error.message);
    }
  };

  return(
    <PeminjamanContext.Provider
    value={{ 
        peminjam,
        kembali,
        loading,
        error,
        fetchPeminjaman,
        addPeminjaman,
        pengembalian,
        fetchPengembalian
     }}
    >
        {children}
    </PeminjamanContext.Provider>
  );
};

export { PeminjamanProvider, PeminjamanContext };