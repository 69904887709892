import React, { createContext, useState } from 'react';
import axios from 'axios';
import API_URL from '../apiConfig';

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  const fetchData = async (url, method = 'GET', data = null) => {
    try {
      const response = await axios({
        method: method,
        url: url,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deleteUser = async (id) => {
    try {
      await fetchData(`${API_URL}/api/user/${id}`, 'DELETE');
      setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const updateUser = async (id, updatedUser) => {
    try {
      const response = await fetchData(`${API_URL}/api/user/${id}`, 'PUT', updatedUser);
      setUsers(prevUsers => prevUsers.map(user => (user.id === id ? response : user)));
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const createUser = async (newUser) => {
    try {
      const response = await fetchData(`${API_URL}/api/user`, 'POST', newUser);
      setUsers(prevUsers => [...prevUsers, response]);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  return (
    <UsersContext.Provider value={{ users, setUsers, fetchData, deleteUser, updateUser, createUser }}>
      {children}
    </UsersContext.Provider>
  );
};