import React, { useContext, useEffect, useState } from 'react';
import { DendaContext } from '../context/DendaContext';
import swal from 'sweetalert';

const Denda = () => {
  const { denda, loading, error, fetchDenda, editDenda } = useContext(DendaContext);
  const [editId, setEditId] = useState(null);
  const [editedDenda, setEditedDenda] = useState({});

  useEffect(() => {
    fetchDenda();
  }, []);

  const handleEdit = (id) => {
    setEditId(id);
    const selectedDenda = denda.find(d => d.id === id);
    setEditedDenda(selectedDenda);
  };

  const handleSaveEdit = async () => {
    await editDenda(editId, editedDenda);
    setEditId(null);
    setEditedDenda({});
    swal("Berhasil!", "User berhasil dihapus.", "success");
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Denda</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left">ID</th>
            <th className="px-4 py-2 text-left">Jumlah Denda</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {denda.map(d => (
            <tr key={d.id}>
              <td className="px-4 py-2">{d.id}</td>
              <td className="px-4 py-2">
                {editId === d.id ? (
                  <input
                    type="text"
                    value={editedDenda.jumlah_denda}
                    onChange={(e) => setEditedDenda({ ...editedDenda, jumlah_denda: e.target.value })}
                    className="border rounded px-2 py-1 w-full"
                  />
                ) : (
                  d.jumlah_denda
                )}
              </td>
              <td className="px-4 py-2">
                {editId === d.id ? (
                  <>
                    <button onClick={handleSaveEdit} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 mr-2 rounded">Save</button>
                    <button onClick={() => setEditId(null)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded">Cancel</button>
                  </>
                ) : (
                  <button onClick={() => handleEdit(d.id)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">Edit</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Denda;
