import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../apiConfig';

const BukuContext = React.createContext();

const BukuProvider = ({ children }) => {
  const [bukus, setBukus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBukus();
  }, []);

  const fetchBukus = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/buku/viewbuku`);
      setBukus(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const addBuku = async (buku) => {
    try {
      const response = await axios.post(`${API_URL}/api/buku/addbuku`, buku);
      setBukus([...bukus, response.data]);
    } catch (error) {
      setError(error.message);
    }
  };

  const editBuku = async (id, buku) => {
    try {
      const response = await axios.put(`${API_URL}/api/buku/editbuku/${id}`, buku);
      setBukus(prevBukus => prevBukus.map((b) => (b.id === id ? response.data : b)));
    } catch (error) {
      setError(error.message);
    }
  };

  const deleteBuku = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/buku/deletebuku/${id}`);
      setBukus(bukus.filter((b) => b.id !== id));
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <BukuContext.Provider
      value={{
        bukus,
        loading,
        error,
        fetchBukus,
        addBuku,
        editBuku,
        deleteBuku,
      }}
    >
      {children}
    </BukuContext.Provider>
  );
};

export { BukuProvider, BukuContext };