import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';

const Home = () => {
  const { userData } = useContext(UserContext);

  if (!userData) {
    return (
        <div className="bg-white p-8 rounded shadow-md">
          <h1 className="text-3xl font-bold mb-4">Memuat data...</h1>
        </div>
    );
  }

  return (
      <div className="bg-white p-8 rounded shadow-md">
        <h1 className="text-3xl font-bold mb-4">Selamat Datang, {userData.name}!</h1>
        <div className="text-lg mb-4">
          <p><strong>Nama:</strong> {userData.name}</p>
          <p><strong>Role:</strong> {userData.role}</p>
        </div>
      </div>
  );
};

export default Home;
